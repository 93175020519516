/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "./theme/resposive.scss";
@import "./theme/variables.scss";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('assets/fonts/SF-Pro-Display-Light.otf') format('woff2')
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/SFPRODISPLAYREGULAR.OTF') format('woff2')
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('assets/fonts/SFPRODISPLAYBOLD.OTF') format('woff2')
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('assets/fonts/SFPRODISPLAYBOLD.OTF') format('woff2')
}


@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('assets/fonts/SFProTextRegular.ttf') format('woff2')
}

@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('assets/fonts/SFProTextRegular.ttf') format('woff2')
}

html {
  height: 100vh;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.inputfile + label {
  color: black;
  border: 2px #ccc solid;
  border-radius: 5px;
  display: inline-block;
  padding: 10px;
  font-size: 13px;
}

.inputfile:focus + label, .inputfile + label:hover {
  background-color: #dadada;
}

body {
  background: #E5E5E5;
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #27272A;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-primary);
}


._layout-page {
  overflow-x: auto;
  @media (max-width: 900px) {
    padding-top: 0;
    height: 100vh;
    background-color: white;
  }

  @include res_1024 {
    position: relative;
    border-radius: 16px !important;
    padding-left: 355px !important;
    padding-top: 70px;
    padding-bottom: 40px;
  }

  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    img {
      width: 300px;
      height: 300px;
    }

    h4 {
      font-size: 24px;
      font-family: "SF Pro Display", sans-serif;
      font-weight: 500;
      color: var(--ion-color-primary);
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    img {
      width: 200px;
      height: 200px;
    }

    h4 {
      font-size: 24px;
      font-family: "SF Pro Display", sans-serif;
      font-weight: 500;
      color: var(--ion-color-primary);
    }
  }
}

.action-sheet-group.sc-ion-action-sheet-ios {
  background: rgba(249, 249, 249, 0.78) !important;
}

.action-sheet-button.sc-ion-action-sheet-ios {
  border-bottom: 0.5px solid rgba(60, 60, 67, 0.33);
  font-family: 'SF Pro Display', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.action-sheet-title.sc-ion-action-sheet-ios {
  border-bottom: 0.5px solid rgba(60, 60, 67, 0.33);
  font-family: 'SF Pro Text', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
}

._container {
  padding: 10px 10px 0 10px;
}

.bg-header {
  @include res_1024 {
    background: url(./assets/img/img-02.png) 0 0/100% 100% no-repeat;
    height: 35%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.bg-footer {
  @include res_1024 {
    background: url(./assets/img/img-01.png) 0 0/100% 100% no-repeat;
    height: 35%;
    width: 100%;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100%;
}

.h-95 {
  height: 95%;
}



.page-layout {
  background: white;
  height: 100%;
  //border-radius: 15px;
  //height: 100vh;
  .modal-view-header {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    overflow: scroll;
    height: 100%;
  }

  .page-header {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    overflow: scroll;
    height: 100vh;
  }

  .function {
    display: flex;
    width: 100%;
    align-items: center;

    .category {
      display: flex;
      width: 100%;

      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;
        background: var(--primary-opacity-color);
        border-radius: 66px;
        font-family: 'SF Pro Text', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 21px;
        color: var(--primary-color);
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }

    img {
      width: 42px;
    }
  }

  .page-header-detail {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;

    .div-header {
      display: flex;
      justify-content: space-between;
      font-family: 'SF Pro Text', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 21px;
      padding-bottom: 10px;

      .btn-cancel {
        display: flex;
        align-items: center;
        background: #E3E3E4;
        color: #71717A;
        border-radius: 65px;
        padding: 4px;

        .label {
          padding-right: 10px;
          padding-left: 10px;
        }

        img {
          width: 42px;
          margin-bottom: -3px;
        }
      }

      .btn-submit {
        display: flex;
        align-items: center;
        color: var(--primary-color);
        background: var(--primary-opacity-color);
        border-radius: 65px;
        padding: 4px;

        .label {
          padding-right: 10px;
          padding-left: 10px;
        }

        img {
          width: 42px;
          margin-bottom: -3px;
        }
      }
    }
  }
}

.post-info {
  display: flex;
  align-items: center;

  img {
    width: 40px;
  }

  .info {
    margin-left: 10px;

    .name {
      display: flex;
      align-items: center;
      font-family: SF Pro Display, sans-serif;
      font-style: normal;

      ._sub {
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #A1A1AA;
      }

      ._name {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #27272A;
      }
    }

    .date-time {
      font-family: Poppins, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #9F9F9F;
    }
  }
}

.create-new-post-msg-question {
  --backdrop-opacity: 0.3;
  --border-radius: 25px 25px 0px 0px;
}

.report-component {
  @include res_768 {
    --backdrop-opacity: 0.3 !important;
    --border-radius: 25px 25px;

  }
  --backdrop-opacity: 1 !important;
  --border-radius: 25px 25px 0px 0px;
}

.create-new-post-information {
  @include res_1024 {
    --border-radius: 20px;
    --max-width: 500px;
    --height: 90%;
  }
  @include res_h_900 {
    --height: 55%;
  }
}


.modal-logout-question {
  @include res_1024 {
    --border-radius: 16px;
    --max-width: 500px;
    --height: 90%;
  }
  @include res_h_900 {
    --height: 55%;
  }
}


.modal-edit-profile {
  @include res_1024 {
    --border-radius: 10px;
    --max-width: 550px;
    --height: calc(100% - 100px);
  }
}

